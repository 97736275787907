import { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { DIRECT_CHAT_ROOM } from "@/react/components/constants";
import { ErrorBoundary } from "@/react/components/shared/ErrorPages/ErrorBoundary";
import { useWebSocket } from "@/react/hooks/useWebSocket";
import { BotChatItem } from "@circle-react/components/Modals/MessagingModal/LeftSidebar/List/BotChatItem";
import { usePinnedBotChat } from "@circle-react/components/SettingsApp/CommunityInbox/hooks/usePinnedBotChat";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { usePunditUserContext } from "@circle-react/contexts";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { useChatsSidebarApi } from "@circle-react/hooks/chatsV2/useChatsSidebarApi";
import { Loader } from "@circle-react-uikit/Loader";
import { usePopoverPortalContext } from "@circle-react-uikit/PopoverPortal";
import { Item } from "./Item";
import { ItemWrapper } from "./Item/ItemWrapper";
import { NoMessages } from "./Item/NoMessages";
import { ListWrapper } from "./ListWrapper";

const MAX_UNREAD_UUIDS = 2000;

export const List = ({ unreadChatRoomUuids }) => {
  const { uuid: chatRoomUuid } = useParams();
  const { onClose } = usePopoverPortalContext() || {};
  const {
    currentCommunityMember,
    isLoading: isCommunityLoading,
    currentCommunitySettings,
  } = usePunditUserContext();
  const { community_bot_enabled: isCommunityBotEnabled } =
    currentCommunitySettings || {};

  const {
    chatRooms,
    hasNextPage,
    isLoading: isChatRoomsLoading,
    onEventReceive,
    fetchNextPage,
  } = useChatsSidebarApi({
    openChatRoomUuid:
      chatRoomUuid && chatRoomUuid !== "new" ? chatRoomUuid : null,
    uuids: unreadChatRoomUuids?.slice(0, MAX_UNREAD_UUIDS),
  });

  useWebSocket(
    {
      channel: "ChatRoomChannel",
      onMessageReceive: data => {
        onEventReceive(data, currentCommunityMember?.id);
      },
      community_member_id: currentCommunityMember?.id,
      canCreateConnection: true,
    },
    [chatRooms],
  );

  const { botChat, isLoading: isBotChatLoading } = usePinnedBotChat();
  const shouldShowBotChat = isCommunityBotEnabled && botChat;

  const isLoading =
    isCommunityLoading ||
    isChatRoomsLoading ||
    (isCommunityBotEnabled && isBotChatLoading);

  const chatRoomWithMessages = useMemo(
    () => chatRooms.filter(chatRoom => chatRoom.last_message),
    [chatRooms],
  );
  const isEmpty = !chatRoomWithMessages?.length && !botChat;

  if (isLoading) {
    return (
      <ListWrapper>
        <Loader center />
      </ListWrapper>
    );
  }

  if (isEmpty) {
    return (
      <ListWrapper>
        <NoMessages />
      </ListWrapper>
    );
  }
  return (
    <ListWrapper>
      {shouldShowBotChat && <BotChatItem chat={botChat} />}
      <InfiniteScroll
        style={{ height: "100%", overflow: "hidden", marginTop: "8px" }}
        scrollThreshold={0.8}
        next={fetchNextPage}
        hasMore={hasNextPage}
        dataLength={chatRoomWithMessages.length}
        scrollableTarget="scrollable-dms-div"
        loader={<Loader center />}
      >
        {chatRoomWithMessages.map(
          ({
            chat_room_name,
            chat_room_kind,
            last_message,
            current_participant = {},
            other_participants_preview: otherParticipants = [],
            id,
            unread_messages_count,
            uuid,
            pinned_at,
          }) => {
            const isDirect = chat_room_kind === DIRECT_CHAT_ROOM;
            return (
              <ItemWrapper key={id} onClose={onClose} uuid={uuid}>
                <Item
                  chatRoomName={chat_room_name}
                  lastMessage={last_message}
                  currentParticipant={current_participant}
                  otherParticipants={otherParticipants}
                  isDirect={isDirect}
                  unreadMessagesCount={unread_messages_count}
                  timestamp={dateStringToTimeAgo(last_message?.created_at, {
                    format: "short",
                  })}
                  currentCommunityMember={currentCommunityMember}
                  isPinned={!!pinned_at}
                />
              </ItemWrapper>
            );
          },
        )}
      </InfiniteScroll>
    </ListWrapper>
  );
};

const ChatError = () => (
  <div className="flex h-full w-full flex-col items-center justify-center">
    <Typography.LabelSm>{t("chat_space.error_message")}</Typography.LabelSm>
  </div>
);

export const DMsList = props => (
  <ErrorBoundary renderFunc={ChatError}>
    <List {...props} />
  </ErrorBoundary>
);
